import React from 'react'
import SEO from '../components/SEO'
import ThankYou from '../components/ThankYou'

function thankyou() {
  return (
    <>
      <SEO title="Thank you for writing me!" />
      <ThankYou />
    </>
  )
}

export default thankyou
