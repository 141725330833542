import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { ButtonStyles, PageWrapper } from '../styles/GenericStyles'

const ThankYouWrapper = styled(PageWrapper)`
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    h3 {
      margin-top: 2em;
    }
  }
`
const ImgStyles = styled(Img)`
  width: 60%;
  height: 40vh;
`

const StyledLink = styled(Link)`
  ${ButtonStyles}
  display: inline-block;
  margin-top: 2em;
`

function ThankYou() {
  const data = useStaticQuery(graphql`
    query {
      dog: sanityPicture(id: { eq: "-198c8f42-95e0-57fa-aee1-60a151eb951b" }) {
        altText
        picture {
          asset {
            fluid(maxWidth: 800, maxHeight: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)
  return (
    <ThankYouWrapper>
      <div className="container">
        <ImgStyles
          fluid={data.dog.picture.asset.fluid}
          alt={data.dog.altText}
        />
        <p>is she answering yet?</p>
        <h3>Thank you for writing me! I usually write back in 1-2 days.</h3>
        <StyledLink deg="rotate(-5deg)" to="/">
          Go back
        </StyledLink>
      </div>
    </ThankYouWrapper>
  )
}

export default ThankYou
